<template>
  <div class="container chat">
    <!-- <Search :isActiveFilter="btnFilter" @inputchange="search" @filter="filter.flag = !filter.flag" v-if="agreements !== null" />
    <p class="list-not" v-if="listNot">За вашим запитом нічого не знайдено</p> -->
    <ul class="block-list">
      <li class="grey"
        v-for="(list, index) in chatList"
        :key="index"
        tag="li"
        @click.prevent="push(list.owner_id, list.owner_type)"
        :data-id="list.owner_id"
      >
        <a style="display: block">
          <i></i>
          <h4 v-if="$root.app == 'SoftProCrmAppRioTransTest' || $root.app == 'SoftProCrmAppRioTrans'">{{(list.owner_type == 'order' ? $root.langText.directory.orderOne : $root.langText.directory.quotes) + ' №' + list.owner_number}}</h4>
          <h4 v-else>{{(list.owner_type == 'order' ? $root.langText.directory.orderOtherOne : $root.langText.directory.quotesOther) + ' №' + list.owner_number}}</h4>
          <p>{{list.client_name}}</p>
          <b>{{list.message}}</b>
          <span class="data">{{list.date_message | date}}</span>
          <span v-if="list.num_unread !== 0" class="number">{{list.num_unread}}</span>
        </a>
      </li>
    </ul>
    <a href="#"
      style="color: #004d9d;margin-top: 20px;display:block"
      v-if="chatList.length > +start && chatList.length >= 20"
      @click.prevent="nextList">{{$root.langText.more}} {{(+chatList.length - +start) >= 20 ? '20' : +chatList.length - +start}}
    </a>
    <!-- <Filters @filter="filters" @isActiveFilter="isActiveFilter" :filter="filter"/> -->
  </div>
</template>


<style lang="scss">
  @import '../../assets/chat.css';
</style>

<script>
import Search from "@/components/app/Search"
import Filters from "@/components/app/Filters"




export default {
  data: () => ({
    chatList: '',
    setinerval: '',


    filter: {
      flag: true,
      mod: 'agreements'
    },
    btnFilter: false,
    listNot: false,

    start: 0,
    limit: 20,

    globalTitle: [
      {title: 'Мої чати', info: ''}
    ],
  }),





  async created() {

    this.globalTitle[0].title = this.$root.langText.menu.chat
    this.$root.title = this.globalTitle

    
    var data = {
      start: this.start,
      num: this.limit,
      action: 'chats_list',
    }

    const chatList = await this.$store.dispatch('getChat', data);
    this.chatList = chatList.data.list
    if(this.chatList){
      //notification
      localStorage.setItem('notificationMess', false)
      var date = Math.round(new Date().getTime() / 1000)
      let app = this
      let arr = []
      this.setinerval = window.setInterval(async () => {
        app.chatList.forEach(function(item, index){
          arr.push(item.id)
        })
        var data = {
          action: 'orders_list',
          ids: arr,
          date_from: date
        }
        var natification = await app.$store.dispatch('getNatification', data)
        if(natification.data.has_updates){
          
        }
      }, 3000)
    }


  },




  methods: {


    push(id, type){
      this.$router.push({name: 'ChatMessage', params: {id: id+"&"+type}})
    },

    async nextList(){
      this.start = +this.start + 20
      var data = {
        start: this.start,
        num: this.limit,
        action: 'chats_list',
      }
      const chatList = await this.$store.dispatch('getChat', data);
      var app = this
      chatList.data.list.forEach(function(item, index){
        app.chatList.push(item)
      })
    }

  },



  components:{
    Search, Filters
  },


  beforeDestroy() {
    clearInterval(this.setinerval)
  },
  
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.chat)
    }
  },
}
</script>